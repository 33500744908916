import * as Yup from 'yup';
import { useState, useEffect } from 'react';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
// ----------------------------------------------------------------------

export default function AddNotes() {



    const theme =useTheme()

    const [subject, setSubject] = useState()
    const [loading, setLoading] = useState(true)



    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);

  const animatedComponents = makeAnimated();

    const [ categories , setCategories] = useState([]);
    const [selectedCategory , setSelectedCategory] = useState([])
    const handleChangeValues = (data) => {
       setSelectedCategory(data);
    }

    const [ subCategories , setSubCategories] = useState([]);
    const [selectedSubCategory , setSelectedSubCategory] = useState([])
    const handleChangeValuesOfSubCategory = (data) => {
       setSelectedSubCategory(data);
    }

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        subject: Yup.string().required('subject is required'),
        // file_url: Yup.string().required('Url  is required'),
        language: Yup.string().required('Language is required'),
        notes_type: Yup.string().required('Type Required'),
        is_active: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        subject: '',
        // file_url: '',
        category: "" ,
        subCategory : "" , 
        language: '',
        notes_type: '',
        is_active: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;
    const [file, setFile] = useState()


    const changeHandler = async (event) => {
        setFile(event.target.files[0])


    };
    const onSubmit = async (data) => {
        if (file?.size) {
            setLoading(true)
            const formData = new FormData();
            formData.append('file', file);
            formData.append('title', data.title);
            formData.append('subject', data.subject);
            formData.append('language', data.language);
            formData.append('notes_type', data.notes_type);
            formData.append('is_active', data.is_active);
            formData.append('category', selectedCategory.value);
            formData.append('subCategory', selectedSubCategory.value);
            // formData.append('is_active', data.is_active);
            // formData.append('fileName', file.name);
            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
            };
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addNotesDetails`, formData, config).then((response) => {
                //console.log(response.data);
                if (response.data.status ) {
                    toast.success(response.data.msg)
                    setFile(null)
                    setShowBanner(false)
                    setFocused(false)
                    setVal(false)
                    setMsg('')
                    setLoading(false)
                    reset();
                    setSelectedCategory({});
                    setSelectedSubCategory({});
                }else{
                    toast.error(response.data.msg);
                    setLoading(false);
                }
            })
                .catch(e => {
                    //console.log(e);
                })
        }



    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchUsers() {

            const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },

            });
            const resSubject = await res.json();
            setSubject(resSubject.data);

            const categoryResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const categoryResponseJson = await categoryResponse.json();
              setCategories(categoryResponseJson.data ?? []);

              const subCategoryResponse = await fetch(
                `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const subCategoryResponseJson = await subCategoryResponse.json();
              setCategories(subCategoryResponseJson.data ?? []);
            setLoading(false)
        }

        fetchUsers();
            
    }, []);

    useEffect(() => {
        const token = localStorage.getItem("token");
        async function fetchSubCategoryDetails() {
          const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/adminSection/getSubCategoryByCategory?categoryId=${selectedCategory?.value}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const responseJson = await fullResponse.json();
          setSubCategories(responseJson.data ?? []);
        }
        fetchSubCategoryDetails();
      
      setLoading(false);
  
    }, [selectedCategory]);
    const [focus, setFocused] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    //console.log(showBanner)
    //console.log(file)
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Notes</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>

                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="title" label="Title" />
                        <RHFTextField name="subject" label="Subject" select>
                            {subject?.map((sub) => {
                                return <MenuItem value={sub._id}>{sub.title}</MenuItem>
                            })}
                        </RHFTextField>
                    </Stack>

                    <Stack direction="row" spacing={3}>

                        <RHFTextField name="language" label="Language" select>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value="hi">Hindi</MenuItem>
                            <MenuItem value="enhi">Bilingual</MenuItem>
                        </RHFTextField>
                        <Stack direction="column" spacing={0} sx={{ width: '100%' }}>

                            <RHFTextField name="file_url" label="PDF"
                                error={!file && val}
                                onChange={changeHandler}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                InputProps={{
                                    classes: {
                                        input: "CustomTextField"
                                    }
                                }}

                                onClick={() => setShowBanner(true)}
                                type={showBanner || focus ? "file" : "text"}
                                helperText="Pdf Files Only"
                                inputProps={{ accept: "application/pdf" }}
                            />
                            {!file && <Typography sx={{ fontSize: '12px', marginTop: '5px', marginLeft: '10px', color: 'red' }}>{msg}</Typography>}

                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                        <Stack direction='column' sx={{ width : '50%'}}>
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Select Category"
                            components={animatedComponents}
                            value={selectedCategory}
                            onChange={handleChangeValues}
                            menuPortalTarget={document.body} 
                            styles={{placeholder: (base) => ({ ...base, color: "#888", fontStyle: "italic",}), menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isMulti={false}
                            options={categories}
                          />
                        </Stack>
                        <Stack direction='column' sx={{ width : '50%'}}>
                          <Select
                            closeMenuOnSelect={false}
                            placeholder="Select Sub Category"
                            components={animatedComponents}
                            value={selectedSubCategory}
                            onChange={handleChangeValuesOfSubCategory}
                            menuPortalTarget={document.body} 
                            styles={{placeholder: (base) => ({ ...base, color: "#888", fontStyle: "italic",}), menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isMulti={false}
                            options={subCategories}
                          />
                        </Stack>
                    </Stack>

                    <Stack direction="row" spacing={3}>

                        <RHFTextField name="notes_type" label="Type" select >
                            <MenuItem value='shorts'>Shorts</MenuItem>
                            <MenuItem value='NCERT'>NCERT</MenuItem>
                        </RHFTextField>
                        <RHFTextField name="is_active" label="Status" select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')
                        }}>
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '70%', textTransform: 'capitalize' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
