import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import axios from 'axios';

// ----------------------------------------------------------------------

export default function AddYoutube() {


    const theme=useTheme()
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState([]);
    const [val, setVal] = useState(false);
    const [focusedFour, setFocusedFour] = useState(false);

    // Handles file selection
    const changeHandlerTwo = (event) => {
        const files = Array.from(event.target.files);
        setNotes(files);
        setVal(files.length > 0); 
    };
    const animatedComponents = makeAnimated();
    const [selectedCategories , setSelectedCategories] =  useState([]);
    const handleChangeCategories =  (newValue ) => {
        setSelectedCategories(newValue);
    }
    const [selectedSubCategories , setSelectedSubCategories] =  useState([]);
    const handleChangeSubCategories =  (newValue ) => {
        setSelectedSubCategories(newValue);
    }
    const [category , setCategory] =  useState([]);
    const [subCategory , setSubCategory] = useState([]);
    const [subject , setSubject] = useState([]);
        //Getting category
        useEffect(() => {
            const token = localStorage.getItem("token");
            async function fetchUsers() {
              
                setLoading(false)
                const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
    
                });
                const resSubject = await res.json();
                setSubject(resSubject.data);
                
                const allCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin?type=Stream`, {
                    method: 'GET',
    
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
    
                });
                const responseJsonAllCatergory = await allCategory.json();
                setCategory(responseJsonAllCatergory.data);
                // setLoading(false)
                const allSubCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getAllSubCategoryForAdmin`, {
                    method: 'GET',
    
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    }
    
                });
                const responseJsonAllSubCatergory = await allSubCategory.json();
                setSubCategory(responseJsonAllSubCatergory.data);
                setLoading(false)
    
            }
    
            fetchUsers();
        }, []);

    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Title required'),
        video_url: Yup.string().required('Url No. is required'),
        language: Yup.string().required('Language is required'),
        desc: Yup.string().required('Language is required'),

       
        
        is_active: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        // category: '',
        video_url: '',
        language: '',
        desc : "" , 
        // link:"",
        // linkWith:'',
        is_active: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async (data) => {
        // console.log(data);
        setLoading(true)
        const formData = new FormData();
        notes.forEach((file, index) => {
            formData.append(`notes`, file);
        });
        formData.append("title" , data?.title);
        formData.append("video_url" , data?.video_url);
        formData.append('subject' , data?.subject);
        formData.append('language' , data?.language);
        formData.append('is_active' , data.is_active);
        formData.append("desc" , data?.desc) ; 
        formData.append("category" , "");
        formData.append("subCategory" , "");
        formData.append("category" , "");
        formData.append("subCategory" , "");
        selectedCategories.map((item) => formData.append('category', item?.value))
        selectedSubCategories.map((item) => formData.append('subCategory', item?.value))
        let token = localStorage.getItem("token");
        // let categoriesId = selectedCategories.map((item) => { return item?.value})
        // let subCategoryIds = selectedSubCategories.map((item) => { return item?.value})
        try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
            maxBodyLength: Infinity,
            maxContentLength: Infinity,
        };
            const res =  await axios.post(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addYouTubeDetails` , formData ,config )
            // const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/addYouTubeDetails`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //         Authorization: `Bearer ${token}`
            //     },
            //     // body: JSON.stringify({...data , category : categoriesId , subCategory : subCategoryIds, link : type , linkWith : valueId})
            //     body : formData , 
            // })
            // const result = await res.json()
            const result =  res.data ; 
            toast.success(result.msg)
            // setFetchMessage(result.msg)
            if (result.msg === 'added youtube details successfully') {
                setLoading(false)
                reset();
                window.location.href = `/dashboard/youtube`;
            }
        } catch (error) {
            toast.error(error);
            setLoading(false);
        }
    };
   
    
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Add Youtube</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>


                    <RHFTextField name="title" label="Title" />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="video_url" label="Video URL" type='url' />
                        <RHFTextField name="language" label="Language" select>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value="hi">Hindi</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <RHFTextField name="desc" label="Description" />
                    
                    
                    <ReactSelect
                     closeMenuOnSelect={false}
                     components={animatedComponents}
                     value={selectedCategories}
                     onChange={handleChangeCategories}
                     placeholder='Please Select Category for Batch'
                     menuPortalTarget={document.body} 
                     styles={{ menuPortal: base => ({ ...base ,  zIndex: 9999 }) }}
                     isMulti
                     options={category}
                    />
                    <ReactSelect
                     closeMenuOnSelect={false}
                     components={animatedComponents}
                     value={selectedSubCategories}
                     onChange={handleChangeSubCategories}
                     menuPortalTarget={document.body} 
                     placeholder='Please Select Sub Category for Batch'
                     styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                     isMulti 
                     options={subCategory} 
                    />
                    <RHFTextField
                        name="notesPDF"
                        error={!notes && val}
                        onChange={changeHandlerTwo}
                        onFocus={() => setFocusedFour(true)}
                        onBlur={() => setFocusedFour(false)}
                        InputProps={{
                            inputProps: { multiple: true, accept: "application/pdf" },
                            classes: {
                                input: "CustomTextField"
                            }
                        }}
                        label="Notes"
                        type="file"
                    />


                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="subject" label="Subject" select>
                                      {subject?.map((item, index) => {
                                        return (
                                          <MenuItem key={index} value={item._id}>
                                            {item.title}
                                          </MenuItem>
                                        );
                                      })}
                        </RHFTextField>
                        <RHFTextField name="is_active" label="Status" type='checkbox' select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>


                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor:theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}>
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

        </Stack>
    </>
    );
}
