import React, { useEffect, useState } from "react";

import { Button, Divider, Grid, Stack, Typography } from "@mui/material";

import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QuizCard from "./QuizCard";
import { useNavigate } from "react-router-dom";

import "./quiz.css";
import { useTheme } from "@emotion/react";
import QuizGrid from "./QuizGrid";
import getAccess from "../../../utils/getAccess";

const Quiz = () => {

  const theme = useTheme()
  const access = getAccess();
  const [loading, setLoading] = useState(true);
  // const role = localStorage.getItem("role");
  const navigate = useNavigate();

  //Test Series Details
  const [quizDetails, setQuizDetails] = React.useState([]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizes`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setQuizDetails(responseJson.data);
      setLoading(false);
    }
    fetchUsers();
  }, []);

  return (
    <>
      {" "}
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color={theme.palette.primary.mainTheme} loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Quiz">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            // py={2}
            sx={{ width: "90%" }}
          >
            {/* <p>Download sample question template</p> */}
            <Typography className="page-heading">All Quizes</Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '70%'
              }}
            >
              {((access?.access?.includes('42') || access?.accessFortab == 'readWrite') || access?.access.includes('all')) && (
                <Button
                  variant="contained"
                  sx={{
                    width: "20%",
                    alignSelf: "center",
                    marginRight: '10px',
                    background: theme.palette.primary.mainTheme,
                    "&:hover": { background: "red" },
                  }}
                  onClick={() => navigate(`/dashboard/addQuiz`)}
                >
                  Add Quiz
                </Button>
              )}
              {((access?.access?.includes('42') || access?.accessFortab == 'readWrite') || access?.access.includes('all')) && (
                <Button
                  variant="contained"
                  sx={{
                    width: "30%",
                    alignSelf: "center",
                    marginRight: '10px',
                    background: theme.palette.primary.mainTheme,
                    "&:hover": { background: "red" },
                  }}
                  onClick={() => navigate(`/dashboard/addQuestionForMultipleQuizes`)}
                >
                  Add Question for Quizes
                </Button>
              )}

              {((access?.access?.includes('42') || access?.accessFortab == 'readWrite') || access?.access.includes('all')) && (
                <Button
                  variant="contained"
                  sx={{
                    width: "40%",
                    alignSelf: "center",
                    background: theme.palette.primary.mainTheme,
                    "&:hover": { background: "red" },
                  }}
                  onClick={() => navigate(`/dashboard/uploadQuestionsForMultipleQuizes`)}
                >
                  Upload Questions for Quizes
                </Button>
              )}
            </div>

          </Stack>
          <Divider />
          <div>
            <small><a href="https://docs.google.com/spreadsheets/d/1udNEj_SEXpE3TKfCthi1vOO1eMfAm9xSK9G_UW4RHOs/edit?usp=sharing" target="_blank">Bulk Upload Quiz Questions Sample Sheet</a></small> <br></br>
          </div>
          <Divider />
          <Grid
            className="quiz-grid-container"
            container
            spacing={3}
            m={2}
            sx={{ overflowY: "scroll", width: "100%" }}
          >
            {quizDetails.length > 0 ? (
              // quizDetails?.map((item, index) => {
              //   return (
              //     <Grid item xs={12} sm={12} md={8} sx={{ minWidth: "97%" }}>
              //       <QuizCard key={index} item={item} />
              //     </Grid>
              //   );
              // })
              <QuizGrid data={quizDetails} />
            ) : (
              <Grid item xs={12} sm={12} md={8} sx={{ minWidth: "97%" }}>
                "No Quizes"
              </Grid>
            )}
          </Grid>

          <ToastContainer
            position="bottom-center"
            autoClose={1000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Page>
      )}
    </>
  );
};

export default Quiz;
