
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm ,  Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField , } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import "./general.css";
import { useTheme } from '@emotion/react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

// ----------------------------------------------------------------------

export default function UpdateYoutube() {
    const { youtubeId } = useParams();
    const theme=useTheme()
    const [loading, setLoading] = useState(false)
    const [ values , setValues] = useState({});
    const RegisterSchema = Yup.object().shape({
    });
    
    const defaultValues = {
        title: '',
        is_active: '',
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        register , errors,
        handleSubmit, reset,
        control,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    const [notes, setNotes] = useState([]);
        const [val, setVal] = useState(false);
        const [focusedFour, setFocusedFour] = useState(false);
    
        // Handles file selection
        const changeHandlerTwo = (event) => {
            const files = Array.from(event.target.files);
            setNotes(files);
            setVal(files.length > 0); 
        };
        const animatedComponents = makeAnimated();
        const [selectedCategories , setSelectedCategories] =  useState([]);
        const handleChangeCategories =  (newValue ) => {
            setSelectedCategories(newValue);
        }
        const [selectedSubCategories , setSelectedSubCategories] =  useState([]);
        const handleChangeSubCategories =  (newValue ) => {
            setSelectedSubCategories(newValue);
        }
        const [category , setCategory] =  useState([]);
        const [subCategory , setSubCategory] = useState([]);
        const [subject , setSubject] = useState([]);
        
    useEffect(() => {
        
        const token = localStorage.getItem("token");
    async function fetchDetails() {
        const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/adminPanel/getYoutubeVideo/${youtubeId}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const responseJson = await fullResponse.json();
          const res = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getSubjectDetails`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },

        });
        const resSubject = await res.json();
        setSubject(resSubject.data);
        
        const allCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin?type=Stream`, {
            method: 'GET',

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }

        });
        const responseJsonAllCatergory = await allCategory.json();
        setCategory(responseJsonAllCatergory.data);
        // setLoading(false)
        const allSubCategory = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getAllSubCategoryForAdmin`, {
            method: 'GET',

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }

        });
        const responseJsonAllSubCatergory = await allSubCategory.json();
        setSubCategory(responseJsonAllSubCatergory.data);
        // setLoading(false)
          const prevCat=  responseJsonAllCatergory.data.filter((item) => responseJson?.data?.category?.includes(item?.value?.toString()))
          const prevSubCat=  responseJsonAllSubCatergory.data.filter((item) => responseJson?.data?.subCategory?.includes(item?.value?.toString()))
          setSelectedCategories(prevCat);
          setSelectedSubCategories(prevSubCat);
          checkDetails(responseJson.data);
          
    }
    fetchDetails();
    
    setLoading(false);
    
}, []);

    const onChange2 = (e) => {
        
        const { name, value } = e.target;
        // console.log(name , value);
        setValues(prev => ({ ...prev, [name]: value }))
    }
    // for progress bar
    // console.log(values);
    const onSubmit = async (data) => {
        console.log(values);
            setLoading(true);
        const formData = new FormData();
        notes.forEach((file, index) => {
            formData.append(`notes`, file);
        });
        formData.append("title" , values?.title);
        formData.append("video_url" , values?.video_url);
        if( !values?.subject?._id){
          formData.append('subject' , values?.subject);
        }else{
            formData.append('subject' , values?.subject?._id);
        }
        // formData.append('subject' , values?.subject);
        formData.append('language' , values?.language);
        formData.append('is_active' , values.is_active);
        formData.append("desc" , values?.desc) ; 
        formData.append("category" , "");
        formData.append("subCategory" , "");
        formData.append("category" , "");
        formData.append("subCategory" , "");
        selectedCategories.map((item) => formData.append('category', item?.value))
        selectedSubCategories.map((item) => formData.append('subCategory', item?.value));
            const token = localStorage.getItem("token");
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
                maxBodyLength: Infinity,
                maxContentLength: Infinity,
            };
            setProgress(0);
            // setLoading(false);
            axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updatedYouTubeDetails/${youtubeId}`, formData, config).then((response) => {
                if (response.data.status == false ) {
                    toast.error(response.data.msg);
                    setLoading(false);
                }else{
                    setLoading(false);
                    toast.success("Video Updated")
                    reset();
                    window.location.href = "/dashboard/youtube";
                }
            })
            .catch(e => {
                setLoading(false);

                toast.error(e.message);
            })
       
    };
    const checkDetails = (details) => {
        setValues( prev => ({
            ...prev,
            title : details?.title,
            is_active: details?.is_active,
            video_url : details?.video_url,
            language: details?.language,
            desc : details?.desc,
            subject :  details?.subject 

        }));
        

    }
    // console.log(list);
    
    
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>{`Update Youtube Video ${values?.title}`}</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="title"  value={values?.title} onChange={onChange2}/>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="video_url"  value={values?.video_url} type='url' onChange={onChange2}/>
                        <RHFTextField name="language" label="Language" value={values?.language} onChange={onChange2} select>
                            <MenuItem value='en'>English</MenuItem>
                            <MenuItem value="hi">Hindi</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <RHFTextField name="desc" value={values?.desc}  onChange={onChange2}/>                   
                    <ReactSelect
                       closeMenuOnSelect={false}
                       components={animatedComponents}
                       value={selectedCategories}
                       onChange={handleChangeCategories}
                       placeholder='Please Select Category for Batch'
                       menuPortalTarget={document.body} 
                       styles={{ menuPortal: base => ({ ...base ,  zIndex: 9999 }) }}
                       isMulti
                       options={category}
                    />
                    <ReactSelect
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        value={selectedSubCategories}
                        onChange={handleChangeSubCategories}
                        menuPortalTarget={document.body} 
                        placeholder='Please Select Sub Category for Batch'
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        isMulti 
                        options={subCategory} 
                    />
                    <RHFTextField
                        name="notesPDF"
                        error={!notes && val}
                        onChange={changeHandlerTwo}
                        onFocus={() => setFocusedFour(true)}
                        onBlur={() => setFocusedFour(false)}
                        InputProps={{
                            inputProps: { multiple: true, accept: "application/pdf" },
                            classes: {
                                input: "CustomTextField"
                            }
                        }}
                        label="Notes"
                        type="file"
                    />
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="subject" label={values?.subject?.title} select onChange={onChange2}>
                            {subject?.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item._id}>
                                        {item.title}
                                    </MenuItem>
                                    );
                            })}
                        </RHFTextField>
                        <RHFTextField name="is_active"  label={values?.is_active ? "Active" : "Inactive"} value={values?.is_active} onChange={onChange2} select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                        }}>
                        Update Youtube
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
