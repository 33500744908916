
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm ,  Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
// components

import { FormProvider, RHFTextField , } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

import { useTheme } from '@emotion/react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import Async from "react-select/async";

// ----------------------------------------------------------------------

export default function EditResultStory() {
    const { id } = useParams();
    const theme=useTheme()
    const navigate = useNavigate();
    const animatedComponents = makeAnimated();
    const [loading, setLoading] = useState(false)
    const[list , setList] = useState([]);
    const[storyDetails , setStoryDetails] = useState();
    const[category , setCategory] =  useState({});

    const handleChangeCategory =  (newValue ) => {
        setCategory(newValue);
    }
   

    const RegisterSchema = Yup.object().shape({
        
    });

    const[student , setStudent] = useState('');
      const handleChangeValue = (data) => {
          setStudent(data);
        }
        
  
        const callApi  = async (text) => {
          const token = localStorage.getItem("token");
          const fullResponse = await fetch(
                  `${process.env.REACT_APP_LIVE_URL}/adminTeacher/admingetallstudentsdetails?text=${text}`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
          );
          const responseJson = await fullResponse.json();
          return responseJson?.data;
        }
        const promiseOptions = (inputValue) =>{
           return new Promise((resolve) => {
             setTimeout(() => {
              resolve(callApi(inputValue));
               }, 100);
          })
        };
   
    const defaultValues = {
        title: '',
        isActive: '',
    };
    
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        register , errors,
        handleSubmit, reset,
        control,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    //get banner details
    useEffect(() => {
        
        const token = localStorage.getItem("token");
       async function fetchDetails() {
        const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/adminSection/resultStory/${id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const responseJson = await fullResponse.json();
          setStoryDetails(responseJson.data);
          const fullResponse2 = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const responseJson2 = await fullResponse2.json();
        setList(responseJson2.data);
        setStudent(responseJson?.data?.user)
        setCategory(responseJson2?.data?.find((item) => item?.value == responseJson?.data?.category?._id))

    }
    fetchDetails();
    
    setLoading(false);
    
}, []);

    const onChange2 = (e) => {
        const { name, value } = e.target;
        setStoryDetails(prev => ({ ...prev, [name]: value }))
    }

    const onSubmit = async () => {
            setLoading(true);
            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            setProgress(0);
            axios.put(`${process.env.REACT_APP_LIVE_URL}/adminSection/updateSuccessStory`, { ...storyDetails ,category : category?.value  ,  id , user :  student?.value  }, config).then((response) => {
                if (response.data.status == false) {
                    toast.error(response.data.msg);
                    setLoading(false);
                }else{
                    setLoading(false);
                    toast.success("Story Updated")
                    
                    reset();
                    window.location.href = "/dashboard/resultStory";
                }
            })
                .catch(e => {
                })
    };


    
    
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Update Story</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                <Stack sx={{ width : '100%'}}>
                    <Async 
                      components={animatedComponents}
                      menuPortalTarget={document.body}
                      onChange={handleChangeValue}
                      value= { student}
                       styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) , placeholder: base => ({ ...base, color: 'gray' }),}}
                      loadOptions={promiseOptions}
                       placeholder='Please Select User'
                     />
                   </Stack>
                    <RHFTextField name="desc"  value={storyDetails?.desc} onChange={onChange2}/>
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="resultInfo"  value={storyDetails?.resultInfo} onChange={onChange2}/>
                        <RHFTextField name="url"  value={storyDetails?.url} onChange={onChange2}/>
                    </Stack>
                    <ReactSelect
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={category}
                            onChange={handleChangeCategory}
                            placeholder='Please Select Category'
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base ,  zIndex: 9999 }) }}
                            isMulti={false}
                            options={list}
                    />

                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="year"  value={storyDetails?.year} onChange={onChange2}/>
                        <RHFTextField name="isActive"  label={storyDetails?.isActive ? "Active" : "Inactive"} value={storyDetails?.isActive} onChange={onChange2} select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        >
                        Update Story
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Stack>
    </>
    );
}
