import * as Yup from "yup";
import { useState, useEffect } from "react";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Stack, Typography, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components

import { FormProvider, RHFTextField } from "../../../components/hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";
import MyCkEditor from "../../../components/hook-form/MyCkEditor";
import { useTheme } from "@emotion/react";

// ----------------------------------------------------------------------

export default function AddQuizQuestion() {
  const { quizId } = useParams();

  const theme = useTheme()


  const [subject, setSubject] = useState();
  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState("");
  const [val, setVal] = useState(false);
  const[dataForDesc1 , setDataForDesc1] = useState("");
  const [dataForDesc2 , setDataForDesc2] = useState("")
  const[dataForDesc3 , setDataForDesc3] = useState("");
  const [dataForDesc4 , setDataForDesc4] = useState("")
  //Getting Quiz Name
  const { quizTitle } = useParams();

  const RegisterSchema = Yup.object().shape({
    // question_e: Yup.string().required("Title required"),
    // question_h: Yup.string().required("Title required"),
    option1_e: Yup.string().required("option1 is required"),
    option2_e: Yup.string().required("option2 is required"),
    option3_e: Yup.string().required("option3 is required"),
    option4_e: Yup.string().required("option4 is required"),
    option1_h: Yup.string().required("option2 is required"),
    option2_h: Yup.string().required("option2 is required"),
    option3_h: Yup.string().required("option2 is required"),
    option4_h: Yup.string().required("option2 is required"),

    difficulty_level: Yup.string().required("difficulty_level is required"),
    correctAns: Yup.string().required("correctOption is required"),
    // answer_e: Yup.string().required("answer is required"),
    // answer_h: Yup.string().required("answer is required"),

    // is_active: Yup.boolean().required('Status Required'),
  });
  const [sections , setSections]= useState([]);
  useEffect(() => {
    const fetchSections = async () => {
      try{
        const token = localStorage.getItem('token');
        const  response = await axios.get(`${process.env.REACT_APP_LIVE_URL}/adminSection/getQuizQuestionSections` , 
          { headers : {
            "Authorization" :`Bearer ${token}`
          } })
          // console.log(response);
          if( response.data.status){
            setSections(response.data?.data);
          }else{
            toast.error(response.data.msg);
          }
      }catch(error){
        console.log(error);
      }
    }
    fetchSections();
  })

  const defaultValues = {
    question_e: "",
    question_h: "",
    option1_e: "",
    option2_e: "",
    option3_e: "",
    option4_e: "",
    option1_h: "",
    option2_h: "",
    option3_h: "",
    option4_h: "",
    difficulty_level: "",
    correctAns: "",
    answer_e: "",
    answer_h: "",

    // is_active: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [file, setFile] = useState();

  const changeHandler = async (event) => {
    setFile(event.target.files[0]);
  };

  const onSubmit = async (data) => {
    // //console.log(data)
    setLoading(true);

    const token = localStorage.getItem("token");
    const authToken = token;
    const reqObject = {
      sectionId :  data?.sectionId ?? null , 
      question_title: [
        {
          // e: data?.question_e,
          // h: data?.question_h,
          e : dataForDesc1,
          h : dataForDesc2,
        },
      ],
      que_level: [
        {
          e: data?.difficulty_level.split("_")[0],
          h: data?.difficulty_level.split("_")[1],
        },
      ],
      option1: [
        {
          e: data?.option1_e,
          h: data?.option1_h,
        },
      ],
      option2: [
        {
          e: data?.option2_e,
          h: data?.option2_h,
        },
      ],
      option3: [
        {
          e: data?.option3_e,
          h: data?.option3_h,
        },
      ],
      option4: [
        {
          e: data?.option4_e,
          h: data?.option4_h,
        },
      ],
      answer: [
        {
          // e: data?.answer_e,
          // h: data?.answer_h,
          e : dataForDesc3,
          h : dataForDesc4,
        },
      ],
      correctAns: data?.correctAns,
    };
    // //console.log(reqObject)
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL}/adminPanel/addQuizQuestion/${quizId}`,
        reqObject,
        config
      )
      .then((response) => {
        // axios.post(`http:localhost:3001/api/vi/adminPanel/addTestQuestions/63d26c1a166ac3c884805b91`, formData, config).then((response) => {
        //console.log(response.data);
        if (response.data.msg === "Quiz Question Added Successfully") {
          toast.success(response.data.msg);
          setVal(false);
          setMsg("");
          setDataForDesc1("");
          setDataForDesc2("");
          setDataForDesc3("");
          setDataForDesc4("");
          setLoading(false);
          reset();
        }
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  return (
    <>
      <Stack spacing={3} px={5}>
        <Typography variant="h3">Add Questions ( {quizTitle} )</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ width: "110%" }}>
            <Stack
              sx={{ width: "80%" }}

            >
              <RHFTextField name="sectionId" label="Select Section Id" select>
                    { sections?.map((item) => (
                      <MenuItem value={item?.id} >{item?.title}</MenuItem>
                    )
                    )}
              </RHFTextField>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{ width: "100%" }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                English Language
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "600",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                हिंदी भाषा
              </Typography>
            </Stack>
            <hr sx={{ background: "lightgray", color: "lightgray" }} />
            <Stack direction="row" sx={{ width: "100%" }} spacing={2}>
              {/* <RHFTextField name="question_e" label="Question Title" /> */}
              {/* <RHFTextEditor name="question_e" label="Question Title" /> */}
              <Stack sx={{ width : "50%"}}>
                 <MyCkEditor dataForDesc={dataForDesc1}  setDataForDesc={setDataForDesc1} />
              </Stack>
              {/* <MyCkEditor dataForDesc={dataForDesc1}  setDataForDesc={setDataForDesc1} /> */}
              {/* <RHFTextField name="question_h" label="हिंदी सवाल शीर्षक" /> */}
              {/* <RHFTextEditor name="question_h" label="हिंदी सवाल शीर्षक" /> */}
              <Stack sx={{ width : "50%"}}>
                  <MyCkEditor dataForDesc={dataForDesc2}  setDataForDesc={setDataForDesc2} />
              </Stack>
              
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                multiline
                rows={3}
                maxRows={Infinity}
                name="option1_e"
                label="Option 1"
              />
              <RHFTextField
                multiline
                rows={3}
                maxRows={Infinity}
                name="option2_e"
                label="Option 2"
              />
              <RHFTextField
                multiline
                rows={3}
                maxRows={Infinity}
                name="option1_h"
                label="हिंदी विकल्प 1"
              />
              <RHFTextField
                multiline
                rows={3}
                maxRows={Infinity}
                name="option2_h"
                label="हिंदी विकल्प 2"
              />
            </Stack>
            <Stack direction="row" spacing={3}>
              <RHFTextField
                multiline
                rows={3}
                maxRows={Infinity}
                name="option3_e"
                label="Option 3"
              />
              <RHFTextField
                multiline
                rows={3}
                maxRows={Infinity}
                name="option4_e"
                label="Option 4"
              />
              <RHFTextField
                multiline
                rows={3}
                maxRows={Infinity}
                name="option3_h"
                label="हिंदी विकल्प 3"
              />
              <RHFTextField
                multiline
                rows={3}
                maxRows={Infinity}
                name="option4_h"
                label="हिंदी विकल्प 4"
              />
            </Stack>
            <Stack direction="row" spacing={3}>
              <Stack direction="column" spacing={3} sx={{ width: "50%" }}>
                <Stack direction="row" spacing={3} py={2}>
                  <RHFTextField name="correctAns" label="Correct Option" select>
                    <MenuItem value="1">A</MenuItem>
                    <MenuItem value="2">B</MenuItem>
                    <MenuItem value="3">C</MenuItem>
                    <MenuItem value="4">D</MenuItem>
                  </RHFTextField>
                  <RHFTextField
                    name="difficulty_level"
                    label="Difficulty Level"
                    select
                  >
                    <MenuItem value="easy_आसान">Easy</MenuItem>
                    <MenuItem value="medium_मध्यम">Medium</MenuItem>
                    <MenuItem value="hard_मुश्किल">Hard</MenuItem>
                  </RHFTextField>
                </Stack>
                <Stack >
                  <Typography>Answer</Typography>
                  <MyCkEditor dataForDesc={dataForDesc3} setDataForDesc={setDataForDesc3}/>
                </Stack>
                {/* <RHFTextEditor name="answer_e" label="Answer" /> */}
                {/* <RHFTextField name="answer_e" label="Answer" /> */}
              </Stack>
              <Stack direction="column" spacing={3} sx={{ width: "50%" }}>
                {/* <RHFTextEditor name="answer_h" label="उत्तर" /> */}
                {/* <RHFTextField name="answer_h" label="उत्तर" multiline rows={4.5} /> */}
                <Typography>उत्तर</Typography>
                <MyCkEditor dataForDesc={dataForDesc4} setDataForDesc={setDataForDesc4}/>
              </Stack>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: theme.palette.primary.mainTheme,
                marginTop: "5%",
                "&:hover": { background: theme.palette.primary.mainTheme },
              }}
              onClick={() => {
                setVal(true);
                setMsg("Required");
              }}
            >
              Add
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
