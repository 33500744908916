import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { LoadingButton } from '@mui/lab';
// components

import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import RHFTextEditor from "../../../components/hook-form/RHFTextEditor";


// ----------------------------------------------------------------------

export default function EditScholarshipTest() {
    const { id} = useParams();
  const theme = useTheme()
  dayjs.extend(utc);
dayjs.extend(timezone);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [restrict, setRestrict] = useState('')
//   const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false)
  const [msg, setMsg] = useState('');
  const [val, setVal] = useState(false);
  const [focus, setFocused] = useState(false);
  const [startingAt, setStartingAt] = useState(dayjs());
  const [registrationEndAt, setRegistrationEndAt] = useState(dayjs());
  const [resultDeclaration, setResultDeclaration] = useState(dayjs());
  const[ showImage , setShowImage] = useState(false);
  const [values , setValues] = useState();
  
  
  const [list, setList] = useState([]);

  const RegisterSchema = Yup.object().shape({});

  const defaultValues = {
    title: '',
    description:'',
    duration:'',
    
  };
  const [file, setFile] = useState()

    const[ desc , setDesc] = useState();
    const onChangeDesc = (value) => {
        setDesc(value);
    }
  const onChange2 = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleDelete = () =>{
    setShowImage(false)
    setFile("");
  }
  const handleChange1 = (value) => {
    // console.log(`${value}`);
    setStartingAt(value);
  };
  const handleChange2 = (value) => {
    // console.log(`${value}`);
    setRegistrationEndAt(value)
  };
  const handleChange3 = (value) => {
    // console.log(`${value}`);\
    setResultDeclaration(value);
  };
  const changeHandler = async (event) => {
    setFile(event.target.files)
    var fileName = document.getElementById('file').value.toLowerCase();
    if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
    else { setRestrict('JPG Or PDF') }
    //console.log(file)

  };
  //console.log(file)
  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit, reset,
    formState: { isSubmitting },
  } = methods;

  const [progress, setProgress] = useState();
  // for progress bar

  const onSubmit = async (data) => {
    
      const formData = new FormData();

      setLoading(true)
      // debugger;
      //console.log(file)
      if (file) {
         Object.values(file).forEach(file => {
           formData.append("file", file);
         })
      }
      formData.append('title', values.title);
      formData.append('duration', values.duration);
      formData.append('description', desc);
      formData.append('quizId', values.quizId);
      // formData.append('startingAt', startingAt?.$d);
      // formData.append('resultDeclaration', resultDeclaration?.$d);
      // formData.append("registrationEndAt" , registrationEndAt?.$d);
      formData.append('startingAt', startingAt.tz("Asia/Kolkata").toDate());
      formData.append('resultDeclaration', resultDeclaration.tz("Asia/Kolkata").toDate());
      formData.append("registrationEndAt" , registrationEndAt.tz("Asia/Kolkata").toDate());
      // .tz("Asia/Kolkata")
      formData.append("isActive", values.isActive);
      const token = localStorage.getItem("token");
      const authToken = token;
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
        onUploadProgress: data => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total))
        },
      };
      // document.querySelector(".mainDiv").style.display = "block";
      // for( let Pair of formData){
      //   console.log(Pair[0] + " " +Pair[1]);
      // }
      // setProgress(0);
      axios.put(`${process.env.REACT_APP_LIVE_URL}/adminPanel/updateScholarshipTest/${id}`, formData, config).then((response) => {
        //console.log(response.data);
        if (response.data.status) {
          toast.success(response.data.msg);
          setLoading(false);
          setPdf(false);
          reset();
          window.location.href = "/dashboard/scholarshipTests";
          // window.location.reload();
        }else{
          setLoading(false);
        }


      })
        .catch(e => {

        })
    // }
    
  };
  useEffect(() => {
    
      const token = localStorage.getItem("token");
      async function fetchDetails() {
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL}/adminPanel/getQuizes?link=${'scholarshipTest'}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        setList(responseJson.data);

      }
      fetchDetails();
      async function fetchTest(){
        const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/adminPanel/getScholarshipTest/${id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const responseJson = await fullResponse.json();
          // console.log(responseJson);
        setStartingAt(dayjs(responseJson.data?.startingAt , "DD-MM-YYYY HH:mm:ss").tz("Asia/Kolkata"));
        setResultDeclaration(dayjs(responseJson.data?.resultDeclaration , "DD-MM-YYYY HH:mm:ss").tz("Asia/Kolkata")) ;
        setRegistrationEndAt(dayjs(responseJson.data?.registrationEndAt , "DD-MM-YYYY HH:mm:ss").tz("Asia/Kolkata")) ;
        setDesc(responseJson.data?.description)
        if( responseJson.data?.banner){
          setShowImage(true);
      }
        setValues((prev) => ({
          ...prev,
          title : responseJson.data?.title,
          description: responseJson.data?.description,
          duration: responseJson.data?.duration,
          quizId: responseJson.data?.quizId?.id,
          quizTitle : responseJson.data?.quizId?.title , 
          startingAt: responseJson.data?.startingAt,
          registrationEndAt: responseJson.data?.registrationEndAt,
          resultDeclaration: responseJson.data?.resultDeclaration,
          isActive :  responseJson?.data?.isActive ,
          banner :  responseJson?.data?.banner , 
        }))

      }
      fetchTest();
    
    setLoading(false);
  }, []);
  // console.log(values);
  // console.log(values);
  return (<>
    <Stack spacing={3} px={5} >
      <Typography variant='h3'>Update Scholarship Test</Typography>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
        <Stack spacing={3} sx={{ width: '70%' }}>


          <RHFTextField name="title"  value={values?.title} onChange={onChange2} />
          <Stack direction="row" spacing={3}>
            {/* <RHFTextField name="file"
              onChange={changeHandler}
              id="file"
              label="Banner"
              error={!file && val}
              helperText='Image Only'
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              // inputProps={{ multiple: true }}
              InputProps={{
                classes: {
                  input: "CustomTextField"
                }
              }}

              onClick={() => setPdf(true)}
              inputProps={{ accept: "image/*" }}
              type={pdf || focus ? "file" : "text"}
            /> */}
            { showImage ? 
                        <Stack direction='column' sx={{ width: '100%', position: 'relative' }} alignItems="center" spacing={1} >
                        <IconButton sx={{ position: 'absolute', top: '-10px', right: '-10px' }} onClick={() => handleDelete()}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                        <img src={values?.banner} alt="BannerImage" style={{ height: '100px' , width:"200px"}}/>
                    </Stack>
                        :<RHFTextField name="file"
                            onChange={changeHandler}
                            id="file"
                            label="Select image"
                            error={!file && val}
                            helperText='Image Only ( Maximum allowed size 100KB)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            // inputProps={{ multiple: true }}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}

                            onClick={() => setPdf(true)}
                            inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}

                        />}
            {/* <Typography fontSize="10px" color="red" sx={{ display: restrict == '' ? 'none' : 'block' }}>{restrict}</Typography> */}
            <RHFTextField
                name="duration"
                // label="Duration (in Min)"
                type="number"
                value={values?.duration}
                onChange={onChange2}
              />
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" spacing={3}>
                  <DateTimePicker
                    label="Starting At"
                    value={startingAt.$d}
                    onChange={handleChange1}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                  <DateTimePicker
                    label="Registration End At"
                    value={registrationEndAt.$d}
                    onChange={handleChange2}
                    // name="starting_date"
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: "100%" }} />
                    )}
                  />
                            
          </Stack>
          </LocalizationProvider>
          


          <Stack direction="row" spacing={3}>
            <RHFTextField name="quizId" label={values?.quizTitle} value={values?.quizId} select onChange={onChange2}>
                            {list?.map((item, i) => { return <MenuItem key={i} value={item._id}>{item.quiz_title}</MenuItem> })}
                        </RHFTextField>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Result Declaration"
                          value={resultDeclaration.$d}
                          onChange={handleChange3}
                    // name="starting_date"
                          renderInput={(params) => (
                            <TextField {...params} sx={{ width: "100%" }} />
                          )}
                        />
                        </LocalizationProvider>
                        

                        
          </Stack>
          <RHFTextEditor name="description" label="Description" value={desc} onChange={onChangeDesc} />
          <RHFTextField name="isActive"  label={values?.isActive ? "Active" : "Inactive"} value={values?.isActive} onChange={onChange2} select >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
          </RHFTextField>

          {/* <div className="mainDiv">
                        <div className="childDiv" style={{ width: `${progress}%` }}>
                            <span> </span>
                        </div>
                    </div> */}


          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
            sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
            onClick={() => {
              setVal(true)
              setMsg('Required')
            }}>
            Add
          </LoadingButton>
        </Stack>
      </FormProvider>
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
    </Stack>
  </>
  );
}
