
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// form
import { useForm ,  Controller} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
// components

import { FormProvider, RHFTextField , } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

// import "./general.css";
import { useTheme } from '@emotion/react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';

// ----------------------------------------------------------------------

export default function EditResultBanner() {
    const { id } = useParams();
    const animatedComponents = makeAnimated();

    const theme=useTheme()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [restrict, setRestrict] = useState('')
    const [focus, setFocused] = useState(false);
    const [pdf, setPdf] = useState(false)
    const [msg, setMsg] = useState('');
    const [val, setVal] = useState(false);
    
    const[list , setList] = useState([]);
    const[bannerDetails , setBannerDetails] = useState();
    const[ showImage , setShowImage] = useState(true);
    const[category , setCategory] =  useState({});
    const handleChangeCategory =  (newValue ) => {
        setCategory(newValue);
      }
   

    const RegisterSchema = Yup.object().shape({
        
    });
   
    const defaultValues = {
        title: '',
        isActive: '',
    };
    const [file, setFile] = useState()

    const changeHandler = async (event) => {
        setFile(event.target.files)
        var fileName = document.getElementById('file').value.toLowerCase();
        if (fileName.endsWith('.pdf') || fileName.endsWith('.jpg')) { setRestrict('') }
        else { setRestrict('JPG Or PDF') }

    };
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        register , errors,
        handleSubmit, reset,
        control,
        formState: { isSubmitting },
    } = methods;

    const [progress, setProgress] = useState();
    //get banner details
    useEffect(() => {
        
        const token = localStorage.getItem("token");
       async function fetchDetails() {
        const fullResponse = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/adminSection/resultBanner/${id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const responseJson = await fullResponse.json();
          setBannerDetails(responseJson.data);
          const fullResponse2 = await fetch(
            `${process.env.REACT_APP_LIVE_URL}/adminPanel/getCategoryListByAdmin`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const responseJson2 = await fullResponse2.json();
        setList(responseJson2.data);
        setCategory(responseJson2?.data?.find((item) => item?.value == responseJson?.data?.category?._id))
    }
    fetchDetails();
    setLoading(false);
    
}, []);

    const onChange2 = (e) => {
        const { name, value } = e.target;
        setBannerDetails(prev => ({ ...prev, [name]: value }))
    }

    const onSubmit = async () => {
            // console.log(values);
            const formData = new FormData();
            setLoading(true);
            if (file) {
                Object.values(file).forEach(file => {
                        formData.append("file", file);
                    })
            }

            formData.append('title', bannerDetails?.title);
            formData.append('year', bannerDetails?.year);
            formData.append('id',id);
            formData.append('category', category?.value);
            formData.append('isActive', bannerDetails?.isActive);
            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: `Bearer ${authToken}`,
                },
                onUploadProgress: data => {
                    setProgress(Math.round((100 * data.loaded) / data.total))
                },
            };
            setProgress(0);
            axios.put(`${process.env.REACT_APP_LIVE_URL}/adminSection/updateResultBanner`, formData, config).then((response) => {
                //console.log(response.data);
                if (response.data.status == false) {
                    toast.error(response.data.msg);
                    setLoading(false);
                }else{
                    setLoading(false);
                    toast.success("Banner Updated")
                    setPdf(false);
                    reset();
                    window.location.href = "/dashboard/resultBanner";
                }
            })
                .catch(e => {
                })
    };

    const handleDelete = () =>{
        setShowImage(false)
        setFile("");
    }

    
    
    return (<>
        <Stack spacing={3} px={5} >
            <Typography variant='h3'>Update Banner</Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                    <RHFTextField name="title"  value={bannerDetails?.title} onChange={onChange2}/>
                    <RHFTextField name="year"  value={bannerDetails?.year} onChange={onChange2}/>

                    <Stack direction="row" spacing={3}>
                        { showImage ? 
                        <Stack direction='column' sx={{ width: '100%', position: 'relative' }} alignItems="center" spacing={1} >
                        <IconButton sx={{ position: 'absolute', top: '-10px', right: '-10px' }} onClick={() => handleDelete()}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                        <img src={bannerDetails?.banner} alt="BannerImage" style={{ height: '100px' , width:"200px"}}/>
                    </Stack>
                        :<RHFTextField name="file"
                            onChange={changeHandler}
                            id="file"
                            label="Select image"
                            // value={value?.banner_url}
                            error={!file && val}
                            helperText='Image Only (Maximum size 100KB allowed)'
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            InputProps={{
                                classes: {
                                    input: "CustomTextField"
                                }
                            }}

                            onClick={() => setPdf(true)}
                            inputProps={{ accept: "image/*" }}
                            type={pdf || focus ? "file" : "text"}

                        />}

                        
                    </Stack>
                    <ReactSelect
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            value={category}
                            onChange={handleChangeCategory}
                            placeholder='Please Select Category'
                            menuPortalTarget={document.body} 
                            styles={{ menuPortal: base => ({ ...base ,  zIndex: 9999 }) }}
                            isMulti={false}
                            options={list}
                    />

                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="isActive"  label={bannerDetails?.isActive ? "Active" : "Inactive"} value={bannerDetails?.isActive} onChange={onChange2} select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>
                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        onClick={() => {
                            setVal(true)
                            setMsg('Required')
                        }}>
                        Update Banner
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </Stack>
    </>
    );
}
