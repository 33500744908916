import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack,   MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from '@emotion/react';


// ----------------------------------------------------------------------

export default function AddQuizQuestionSection() {

    const theme = useTheme();
      
    const [loading, setLoading] = useState(false)
    
    const RegisterSchema = Yup.object().shape({
        title: Yup.string().required('Result Information Required'),
        isActive: Yup.boolean().required('Status Required'),
    });

    const defaultValues = {
        title: '',
        isActive: '',
    };
    

    
    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });

    const {
        handleSubmit, reset,
        formState: { isSubmitting },
    } = methods;

    // console.log("67" , file);
    

    const onSubmit = async (data) => {
            setLoading(true);
            

            const token = localStorage.getItem("token");
            const authToken = token;
            const config = {
                headers: {
                    'content-type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                
            };
            axios.post(`${process.env.REACT_APP_LIVE_URL}/adminSection/createQuizQuestionSection`, {...data  }, config).then((response) => {
                if (response.data.status) {
                    toast.success(response.data.msg);
                    setLoading(false);
                    reset();
                    window.location.href = "/dashboard/quizQuestionSection";
                } else {
                    toast.error(response.data.msg);
                    setLoading(false);
                }

            })
                .catch(e => {
                    //console.log(e);
                })
        

    };


    return (<>
        <Stack spacing={3} px={5} >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Stack spacing={3} sx={{ width: '70%' }}>
                  
                    <RHFTextField name="title" label="Section Title" />
                   
                    <Stack direction="row" spacing={3}>
                        <RHFTextField name="isActive" label="Status" type='checkbox' select >
                            <MenuItem value={true}>Active</MenuItem>
                            <MenuItem value={false}>Inactive</MenuItem>
                        </RHFTextField>

                    </Stack>
                    <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}
                        sx={{ bgcolor: theme.palette.primary.mainTheme, marginTop: '5%', '&:hover': { background: 'red' } }}
                        >
                        Add
                    </LoadingButton>
                </Stack>
            </FormProvider>
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* <Typography color={positive ? "green" : "red"} sx={{ textAlign: 'center', width: '80%' }}>{fetchMessage}</Typography> */}
        </Stack>
    </>
    );
}
