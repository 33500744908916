// component
import { useContext } from "react";
import Iconify from "../../components/Iconify";
import getAccess from "../../utils/getAccess";


const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const assignBatch = localStorage.getItem("assignBatch")


// it include access number for nav Section

let d = getAccess();
let role = d?.role;

// I -  DashBoard 


// II -  Users ( main Heading)  ->    1 
// subHeading of users 
// i - All Users    -- 1111
//  users --- 111112
// (ii) - Courses Purchasees  --- 1112
// iii - Ebook Purchases ----- 11114
// (iv) - Test Series Purchase    --- 1113
// (v) - Purchase Report   ---- 1114
// (vi) - user RePort    --- 1115
// vii - Call To Action   --- 1116
// viii webSiteSearch ---- 1117
let usersHeading = {};      // ---- 1
if (d?.access?.includes('all') || d?.access?.includes('1111') || d?.access?.includes('111112') || d?.access?.includes('1112') || d?.access?.includes('1112') || d?.access?.includes('1113') || d?.access?.includes('1114') || d?.access?.includes('11114') || d?.access?.includes('1115') || d?.access?.includes('1116') || d?.access?.includes('1117')) {
  usersHeading = {
    title: 'Users',
    path: '#',
    icon: getIcon('eva:people-fill'),
    dropIcon: getIcon('gridicons:dropdown'),
    upIcon: getIcon('mi:caret-up')
  }
}
// subHeading of users 
// i - All Users    -- 1111
let allUsersSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1111')) {
  allUsersSubHeading = {
    title: "All Users ",
    path: "/dashboard/students",
    navicon: getIcon("icon-park-twotone:data-user"),
    cName: "sub-nav",
  }
}
let specificUsersSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('111112')) {
  specificUsersSubHeading = {
    title: "Specific Users ",
    path: "/dashboard/specificStudents",
    navicon: getIcon("icon-park-twotone:data-user"),
    cName: "sub-nav",
  }
}

// (ii) - Courses Purchasees  --- 1112
let coursesPurchasesSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1112')) {
  coursesPurchasesSubHeading = {
    title: "Courses Purchases",
    path: "/dashboard/studentBatches",
    navicon: getIcon("eva:folder-fill"),
    cName: "sub-nav",
  }
}

let courseOrderTableSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('11121')) {
  courseOrderTableSubHeading = {
    title: "Course Orders",
    path: "/dashboard/courseOrders",
    navicon: getIcon("fluent:document-border-print-24-regular"),
    cName: "sub-nav",
  }
}

let ebookPurchasesSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('11114')) {
  ebookPurchasesSubHeading = {
    title: "Ebook Purchases",
    path: "/dashboard/ebookPurchases",
    navicon: getIcon("eva:folder-fill"),
    cName: "sub-nav",
  }
}
// (iii) - Test Series Purchase    --- 1113
let testSeriesPurchasesSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1113')) {
  testSeriesPurchasesSubHeading = {
    title: "Test Series Purchases",
    path: "/dashboard/studentTestSeries",
    navicon: getIcon("eva:file-fill"),
    cName: "sub-nav",
  }
}
// (iv) - Purchase Report   ---- 1114
let purchaseReportSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1114')) {
  purchaseReportSubHeading = {
    title: "Purchase Report",
    path: "/dashboard/purchaseReport",
    navicon: getIcon("carbon:report-data"),
    cName: "sub-nav",
  }
}
// (v) - user RePort    --- 1115
let userReportSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1115')) {
  userReportSubHeading = {
    title: "User Report",
    path: "/dashboard/userReport",
    navicon: getIcon("carbon:report-data"),
    cName: "sub-nav",
  }
}

// vi - Call To Action   --- 1116
let callToSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1116')) {
  callToSubHeading = {
    title: "Call To Action",
    path: "/dashboard/callToAction",
    navicon: getIcon("carbon:report-data"),
    cName: "sub-nav",
  }
}

let webSiteSearchQuerySubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1117')) {
  webSiteSearchQuerySubHeading = {
    title: "WebSite Search Query",
    path: `/dashboard/searchQuery/${'course'}`,
    navicon: getIcon("carbon:report-data"),
    cName: "sub-nav",
  }
}

// III -  Store ( main Heading)     ---- 11
// subHeading of users 
// i - Store Category   --- 111
// (ii) - Store banner   --- 112
// (iii) - featured Video  ---- 113
// (iv) - Alerts   ----- 114
// (v) - All Product  ---- 115
// vi - add Product   ---- 116
// vii - All Trashed Product ---- 1202
// viii - Store User  -------1201
// ix - Store Wishlist ------ 119
// x  - Store Cart ----- 120
// xi - order Report  ----- 117
// xii - Purchase report ----- 118
// xiii - Add Review ------ 1205


// III -  Store ( main Heading)     ---- 11
let storeHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('111') || d?.access?.includes('112') || d?.access?.includes('113') || d?.access?.includes('114') || d?.access?.includes('115') || d?.access?.includes('116') || d?.access?.includes('117') || d?.access?.includes('118') || d?.access?.includes('119') || d?.access?.includes('120') || d?.access?.includes('1201') || d?.access?.includes('1202') || d?.access?.includes('1203') || d?.access?.includes('1204') || d?.access?.includes('1205')) {
  storeHeading = {
    title: "Store",
    path: "#",
    icon: getIcon("dashicons:products"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of users 
// i - Store Category   --- 111
let storeCategory = {};
if (d?.access?.includes('all') || d?.access?.includes('111')) {
  storeCategory = {
    title: "Category",
    path: "/dashboard/storeCategory",
    navicon: getIcon("carbon:category"),
    cName: "sub-nav",
  }
}
// (ii) - Store banner   --- 112
let storeBanner = {}
if (d?.access?.includes('all') || d?.access?.includes('112')) {
  storeBanner = {
    title: "Banner",
    path: "/dashboard/storeBanner",
    navicon: getIcon("game-icons:vertical-banner"),
    cName: "sub-nav",
  }
}
// (iii) - featured Video  ---- 113
let storeFeaturedVideo = {}
if (d?.access?.includes('all') || d?.access?.includes('113')) {
  storeFeaturedVideo = {
    title: "Featured Video",
    path: "/dashboard/storeFeaturedVideo",
    navicon: getIcon("lets-icons:video-duotone"),
    cName: "sub-nav",
  }
}
// (iv) - Alerts   ----- 114
let storeAlerts = {}
if (d?.access?.includes('all') || d?.access?.includes('114')) {
  storeAlerts = {
    title: "Alerts",
    path: "/dashboard/storeAlert",
    navicon: getIcon("fluent:alert-on-20-filled"),
    cName: "sub-nav",
  }
}
// (v) - All Product  ---- 115
let storeProductsSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('115')) {
  storeProductsSubHeading = {
    title: "All Product",
    path: "/dashboard/storeAllProducts",
    navicon: getIcon("gridicons:product"),
    cName: "sub-nav",
  }
}
// vi - add Product   ---- 116
let storeAddProductsSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('116')) {
  storeAddProductsSubHeading = {
    title: "Add Product",
    path: "/dashboard/addStoreProduct",
    navicon: getIcon("carbon:product"),
    cName: "sub-nav",
  }
}
// vii - All Trashed Product ---- 1202
let storeTrashProductsSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1202')) {
  storeTrashProductsSubHeading = {
    title: "All Trashed Product",
    path: "/dashboard/storeTrashProduct",
    navicon: getIcon("gridicons:product"),
    cName: "sub-nav",
  }
}
//  store Coupon --- 1203
let storeCouponSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1203')) {
  storeCouponSubHeading = {
    title: "Store Coupon",
    path: "/dashboard/storeCoupon",
    navicon: getIcon("gridicons:product"),
    cName: "sub-nav",
  }
}
// viii - Store User  -------1201
let storeUsersSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1201')) {
  storeUsersSubHeading = {
    title: "Store User",
    path: "/dashboard/storeUser",
    navicon: getIcon("eva:people-fill"),
    cName: "sub-nav",
  }
}
// ix - Store Wishlist ------ 119
let storeWishlistSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('119')) {
  storeWishlistSubHeading = {
    title: "Store Wishlist",
    path: "/dashboard/storeWishlist",
    navicon: getIcon("carbon:product"),
    cName: "sub-nav",
  }
}
// x  - Store Cart ----- 120
let storeCartSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('120')) {
  storeCartSubHeading = {
    title: "Store Cart",
    path: "/dashboard/storeCarts",
    navicon: getIcon("carbon:product"),
    cName: "sub-nav",
  }
}
// xi - order Report  ----- 117
let storeOrderReportSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('117')) {
  storeOrderReportSubHeading = {
    title: "Order Report",
    path: "/dashboard/storeOrders",
    navicon: getIcon("carbon:product"),
    cName: "sub-nav",
  }
}
// xii - Purchase report ----- 118
let storePurchaseReportSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('118')) {
  storePurchaseReportSubHeading = {
    title: "Purchase Report",
    path: "/dashboard/storePurchaseReport",
    navicon: getIcon("carbon:product"),
    cName: "sub-nav",
  }
}
let storeUserSearchSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1204')) {
  storeUserSearchSubHeading = {
    title: "User Search Query",
    path: `/dashboard/searchQuery/${'store'}`,
    navicon: getIcon("carbon:product"),
    cName: "sub-nav",
  }
}

let storeReviewSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('1205')) {
  storeReviewSubHeading = {
    title: "Review",
    path: `/dashboard/storeReview`,
    navicon: getIcon("carbon:product"),
    cName: "sub-nav",
  }
}

// IV -  Batches ( main Heading)    --- 2
// subHeading of batches
// i - All Batchess   ----- 21
// (ii) -  Add Batches  ----- 22 
// (iii) -   Announcements    ----- 
// (iv) - Batch Feature -- 231
// v) - BAtch Community --- 2311
// vi) batch Doubt  --- 2312

// all lecture -- 23 
// add Lecture -- 24

// IV -  Batches ( main Heading)    --- 2
let batchesHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('21') || d?.access?.includes('22') || d?.access?.includes('25') || d?.access?.includes('231') || d?.access?.includes('2311') || d?.access?.includes('2312') || (parseInt(assignBatch) == 0 ? false : assignBatch?.length > 0 ? true : false)) {
  batchesHeading = {
    title: 'Batches',
    path: '#',
    icon: getIcon('eva:shopping-bag-fill'),
    dropIcon: getIcon('gridicons:dropdown'),
    upIcon: getIcon('mi:caret-up'),
  }
}
// subHeading of batches
// i - All Batchess   ----- 21
let allBatchesSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('21')) {
  allBatchesSubHeading = {
    title: "All batches",
    path: "/dashboard/batches",
    navicon: getIcon("fa-solid:folder"),
    cName: "sub-nav",
  }
}
// (ii) -  Add Batches  ----- 22 
let addBatchSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('22')) {
  addBatchSubHeading = {
    title: "Add Batch",
    path: "/dashboard/addBatch",
    navicon: getIcon("iconamoon:folder-add-fill"),
    cName: "sub-nav",
  }
}
// (iii) -   Announcements    ----- 25
let announcementsSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('25')) {
  announcementsSubHeading = {
    title: "Announcement",
    path: "/dashboard/announcements",
    navicon: getIcon("fa-solid:folder"),
    cName: "sub-nav",
  }
}

let batchFeatureSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('231')) {
  batchFeatureSubHeading = {
    title: "Batch Feature",
    path: "/dashboard/batchFeature",
    navicon: getIcon("fa-solid:folder"),
    cName: "sub-nav",
  }
}
let batchCommunitySubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('2311')) {
  batchCommunitySubHeading = {
    title: "Batch Community",
    path: "/dashboard/allBatchCommunity",
    navicon: getIcon("fa-solid:folder"),
    cName: "sub-nav",
  }
}
let batchDoubtSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('2312')) {
  batchDoubtSubHeading = {
    title: "Batch Doubts",
    path: "/dashboard/allBatchDoubt",
    navicon: getIcon("fa-solid:folder"),
    cName: "sub-nav",
  }
}

// all lecture -- 23 
// add Lecture -- 24



//   learning ( main Heading)    --- 888
// subHeading of learning
// i - Channel   ----- 8881
// (ii) -  Add channel  ----- 8882 
//  iii ) short -- 8883
// iv ) add short --- 8884
let learningHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('8881') || d?.access?.includes('8882') || d?.access?.includes('8883') || d?.access?.includes('8884')) {
  learningHeading = {
    title: "Quick Learning",
    path: "#",
    icon: getIcon("gravity-ui:video"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}

let learningChannelSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('8881')) {
  learningChannelSubHeading = {
    title: "Channel",
    path: "/dashboard/quicklearning/channels",
    navicon: getIcon("grommet-icons:channel"),
    cName: "sub-nav",
  }
}


// V - Test Series  ( main Heading)  ----- 3
// subHeading of batches
// i - All Test Series  -- 31
// (ii) -  Add testSeries  --- 32
// all Test  ---- 33
//  add test ---- 34


// 5 - Test Series  ( main Heading)    ---- 3
let testSeriesHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('31') || d?.access?.includes('32')) {
  testSeriesHeading = {
    title: "Test Series",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of batches
// i - All Test Series   --- 31
let allTestSeriesSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('31')) {
  allTestSeriesSubHeading = {
    title: "All Test Series",
    path: "/dashboard/test",
    navicon: getIcon("mdi:folder-file"),
    cName: "sub-nav",
  }
}
// (ii) -  Add testSeries   --- 32
let addTestSeriesSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('32')) {
  addTestSeriesSubHeading = {
    title: "Add Test Series",
    path: "/dashboard/addTest",
    navicon: getIcon("ant-design:file-add-filled"),
    cName: "sub-nav",
  }
}

// V - Ebook  ( main Heading)  ----- 711
// subHeading of Ebooks
// i - All Ebooks   -- 7111
// (ii) -  Add Ebook  --- 7112



// 5 - Ebook  ( main Heading)    ---- 711
let ebookHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('7111') || d?.access?.includes('7112')) {
  ebookHeading = {
    title: "Ebook",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of batches
// i - All Test Series   --- 31
let allEbookSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('7111')) {
  allEbookSubHeading = {
    title: "All Ebook",
    path: "/dashboard/ebooks",
    navicon: getIcon("mdi:folder-file"),
    cName: "sub-nav",
  }
}
// (ii) -  Add Ebook   --- 32
let addEbookSubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('7112')) {
  addEbookSubHeading = {
    title: "Add Ebook",
    path: "/dashboard/addEbook",
    navicon: getIcon("ant-design:file-add-filled"),
    cName: "sub-nav",
  }
}

// 6 - CMS  ( main Heading)  --- 12
// subHeading of CMS
// i - All Blogs   --- 121
// (ii) -  Add Blog --- 122

// 6 - CMS  ( main Heading)  --- 12
let cmsHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('121') || d?.access?.includes('122')) {
  cmsHeading = {
    title: "CMS",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of CMS
let allBlogsSubHeading = {};
// i - All Blogs   --- 121
if (d?.access?.includes('all') || d?.access?.includes('121')) {
  allBlogsSubHeading = {
    title: "All Blogs",
    path: "/dashboard/blogs",
    navicon: getIcon("mdi:folder-file"),
    cName: "sub-nav",
  }
}

// (ii) -  Add Blog --- 122
let addBlogSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('122')) {
  addBlogSubHeading = {
    title: "Add Blog",
    path: "/dashboard/addBlog",
    navicon: getIcon("ant-design:file-add-filled"),
    cName: "sub-nav",
  }
}


//7 -  Community  ( main Heading)  ---- 13
// subHeading of community
// i - All Community    ---- 131

//7 -  Community  ( main Heading)  ---- 13
let communityHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('131') || d?.access?.includes('13')) {
  communityHeading = {
    title: "Community",
    path: "#",
    icon: getIcon("fluent:people-community-16-filled"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of community
// i - All Community    ---- 131
let allCommunitySubHeading = {}
if (d?.access?.includes('all') || d?.access?.includes('131')) {
  allCommunitySubHeading = {
    title: "All Community",
    path: "/dashboard/community",
    // navicon: "akar-icons:book-close",
    navicon: getIcon("fluent:people-community-16-filled"),
    cName: "sub-nav",
  }
}

//8 - pushNotification  ( main Heading ) --- 14
//  subHeading of PushNotifiaction
//   i - all NOtification --- 141
// ii - add Notification ---- 142

//8 - pushNotification  ( main Heading ) --- 14
let pushNotificationHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('141') || d?.access?.includes('142')) {
  pushNotificationHeading = {
    title: "Push Notification",
    path: "#",
    icon: getIcon("ant-design:notification-filled"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
//  subHeading of PushNotifiaction
//   i - all NOtification --- 141
let allNotificationSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('141')) {
  allNotificationSubHeading = {
    title: "All Notification",
    path: "/dashboard/notification",
    navicon: getIcon("gravity-ui:list-ol"),
    cName: "sub-nav",
  }
}
// ii - add Notification ---- 142
let addNotificationSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('142')) {
  addNotificationSubHeading = {
    title: "Add Notification",
    path: "/dashboard/createNotification",
    navicon: getIcon("ion:notifications-circle-outline"),
    cName: "sub-nav",
  }
}


// IX  - Quiz ( main Heading )  --- 4
// subHeading of Quiz
//  i - All Quiz   --- 41
//  ii - Add Quiz  ---- 42
// quiz Question Section - 43


// IX  - Quiz ( main Heading )  --- 4
let quizHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('41') || d?.access?.includes('42') || d?.access?.includes('43')) {
  quizHeading = {
    title: "Quiz",
    path: "#",
    icon: getIcon("material-symbols:quiz-outline"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of Quiz
//  i - All Quiz   --- 41
let allQuizSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('41')) {
  allQuizSubHeading = {
    title: "All Quiz",
    path: "/dashboard/quiz",
    navicon: getIcon("mdi:quiz"),
    cName: "sub-nav",
  }
}
//  ii - Add Quiz  ---- 42
let addQuizSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('42')) {
  addQuizSubHeading = {
    title: "Add Quiz",
    path: "/dashboard/addQuiz",
    navicon: getIcon("fluent:quiz-new-20-filled"),
    cName: "sub-nav",
  }
}

let quizQuestionSectionSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('42')) {
  quizQuestionSectionSubHeading = {
    title: "Quiz Question Section",
    path: "/dashboard/quizQuestionSection",
    navicon: getIcon("fluent:quiz-new-20-filled"),
    cName: "sub-nav",
  }
}
//11 -  Scholarship Test ( main Heading ) ---- 15
// subHeading of Scholar Ship
//  i - All Scholarship test    ---- 151
//  ii - Add Scholarship    --- 152

//11 -  Scholarship Test ( main Heading ) ---- 15
let scholarshipTestHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('151') || d?.access?.includes('152')) {
  scholarshipTestHeading = {
    title: "Scholarship Test",
    path: "#",
    icon: getIcon("simple-icons:googlescholar"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of Scholar Ship
//  i - All Scholarship test    ---- 151
let allScholarshipTestSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('151')) {
  allScholarshipTestSubHeading = {
    title: "All Scholarship Test",
    path: "/dashboard/scholarshipTests",
    navicon: getIcon("academicons:semantic-scholar-square"),
    cName: "sub-nav",
  }
}
//  ii - Add Scholarship    --- 152
let addScholarshipTestSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('152')) {
  addScholarshipTestSubHeading = {
    title: "Add Scholarship Test",
    path: "/dashboard/addScholarshipTest",
    navicon: getIcon("gg:add"),
    cName: "sub-nav",
  }
}

//  1 -  Deep Link ( main Heading ) ---- 22
// subHeading of Deep Link
//  i - All Deep Link    ---- 221
//  ii - Add Deep Link    --- 222

//11 -  Scholarship Test ( main Heading ) ---- 15
let deepLinkHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('221') || d?.access?.includes('222')) {
  deepLinkHeading = {
    title: "Dynamic Link",
    path: "#",
    icon: getIcon("eva:link-2-outline"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of Scholar Ship
//  i - All Scholarship test    ---- 151
let allDeepLinkSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('221')) {
  allDeepLinkSubHeading = {
    title: "All Dynamic Links",
    path: "/dashboard/dynamicLinks",
    navicon: getIcon("eva:link-outline"),
    cName: "sub-nav",
  }
}
//  ii - Add Scholarship    --- 152
let addDeepLinkSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('152')) {
  addDeepLinkSubHeading = {
    title: "Add Dynamic Link",
    path: "/dashboard/addDynamicLink",
    navicon: getIcon("eva:link-outline"),
    cName: "sub-nav",
  }
}


// 12 - Coupon Test ( main Heading ) ---- 5
// subHeading of Coupon
//  i - All Coupon   --- 51
//  ii - Add Coupon   --- 52


// 12 - Coupon Test ( main Heading ) ---- 5
let couponHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('51') || d?.access?.includes('52')) {
  couponHeading = {
    title: "Coupon",
    path: "#",
    icon: getIcon("ri:coupon-3-line"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
// subHeading of Coupon
//  i - All Coupon   --- 51
let allCouponSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('51')) {
  allCouponSubHeading = {
    title: "All Coupon",
    path: "/dashboard/allCoupon",
    navicon: getIcon("icon-park-solid:coupon"),
    cName: "sub-nav",
  }
}
//  ii - Add Scholarship    --- 52
let addCouponSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('52')) {
  addCouponSubHeading = {
    title: "Add Coupon",
    path: "/dashboard/addCoupon",
    navicon: getIcon("eva:plus-square-fill"),
    cName: "sub-nav",
  }
}

// 13 - Resourses ( main Heading)  ---- 6
//   subHeading of Resourses
//   i - Recorded Video   ---61
//   add recorder Video ----  611
//  ii - PathyaKram ---- 62 
//  add pathyakram ---- 622
// iii - Notes ---- 63 
//  add Notes ----- 633
// iv - PYQs   --- 64
//  add PYQS ---- 644
// v - AIR    ---65
//  add Air ---- 655
// vi - news Clip  ---- 66
//  add new Clip ---- 666
// vii - Teacher Generated content of Batch ---- 67
//  add teacher generated content of batch ----- 677
// viii - Alerts ---- 68 
//  add Alert ----- 688


// 13 - Resourses ( main Heading)  ---- 6
let resourcesHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('61')
  || d?.access?.includes('611') || d?.access?.includes('62') || d?.access?.includes('622') ||
  d?.access?.includes('63') || d?.access?.includes('633')
  || d?.access?.includes('64') || d?.access?.includes('644')
  || d?.access?.includes('65') || d?.access?.includes('655')
  || d?.access?.includes('66') || d?.access?.includes('666')
  || d?.access?.includes('67') || d?.access?.includes('677')
  || d?.access?.includes('68') || d?.access?.includes('688')) {
  resourcesHeading = {
    title: "Resources",
    path: "#",
    icon: getIcon("eva:alert-triangle-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
//   subHeading of Resourses
//   i - Recorded Video   ---61
//   add recorder Video ----  611
let recordedVideoSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('61') || d?.access?.includes('611')) {
  recordedVideoSubHeading = {
    title: "Recorded Video",
    path: "/dashboard/recorded",
    navicon: getIcon("ph:video-fill"),
    cName: "sub-nav",
  }
}
//  ii - PathyaKram ---- 62 
//  add pathyakram ---- 622
let pathyaKramSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('62') || d?.access?.includes('622')) {
  pathyaKramSubHeading = {
    title: "Pathyakram",
    path: "/dashboard/pathyakram",
    navicon: getIcon("clarity:list-line"),
    cName: "sub-nav",
  }
}


// iii - Notes ---- 63 
//  add Notes ----- 633
let notesSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('63') || d?.access?.includes('633')) {
  notesSubHeading = {
    title: "Notes",
    path: "/dashboard/notes",
    navicon: getIcon("icon-park-solid:notes"),
    cName: "sub-nav",
  }
}

// iv - PYQs   --- 64
//  add PYQS ---- 644
let pyqsSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('64') || d?.access?.includes('644')) {
  pyqsSubHeading = {
    title: "Previous Year Question Papers",
    path: "/dashboard/previousYearQuestionPapers",
    navicon: getIcon("academicons:philpapers-square"),
    cName: "sub-nav",
  }
}
// v - AIR    ---65
//  add Air ---- 655
let airSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('65') || d?.access?.includes('655')) {
  airSubHeading = {
    title: "AIR",
    path: "/dashboard/air",
    navicon: getIcon("mdi:air-horn"),
    cName: "sub-nav",
  }
}
// vi - news Clip  ---- 66
//  add new Clip ---- 666
let newsClipSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('66') || d?.access?.includes('666')) {
  newsClipSubHeading = {
    title: "News Clip",
    path: "/dashboard/newsClip",
    navicon: getIcon("typcn:news"),
    cName: "sub-nav",
  }
}
// vii - Teacher Generated content of Batch ---- 67
//  add teacher generated content of batch ----- 677
let teacherGCOBSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('67') || d?.access?.includes('677')) {
  teacherGCOBSubHeading = {
    title: "Teacher Generated Content of Batch",
    path: "/dashboard/teacherGenerated",
    navicon: getIcon("fluent:content-view-20-filled"),
    cName: "sub-nav",
  }
}
// v - All alert    ---68
//  add alert ---- 688
let allAlertSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('68') || d?.access?.includes('688')) {
  allAlertSubHeading = {
    title: "Alerts",
    path: "/dashboard/customNotification",
    navicon: getIcon("fluent:alert-on-20-filled"),
    cName: "sub-nav",
  }
}



// assignment ( main Heading)    --- 7
//  subHeading 
//  i - all assignments ---- 71
//  ii add assignments ----- 72


let assignmentHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('71') || d?.access?.includes('72')) {
  assignmentHeading = {
    title: "Assignment",
    path: "/dashboard/assignment",
    icon: getIcon("eva:book-fill"),
    // dropIcon: getIcon("gridicons:dropdown"),
    // upIcon: getIcon("mi:caret-up"),
  }

}

// manageStaff ( main Heading)
let staffHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('81') || d?.access?.includes('82')) {
  staffHeading = {
    title: "Manage Staff",
    path: "/dashboard/staff",
    icon: getIcon("ic:outline-manage-accounts"),
    // dropIcon: getIcon("mi:caret-down"),
    // upIcon: getIcon("mi:caret-up"),
  }

}

// Testimonal ( main Heading)
//   subHeading
// All Testimonal - 91
//  add Testimonal - 92

let testimonialHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('91') || d?.access?.includes('92')) {
  testimonialHeading = {
    title: "Testimonial",
    path: "/dashboard/testimonial",
    icon: getIcon("dashicons:testimonial"),
    // dropIcon: getIcon("gridicons:dropdown"),
    // upIcon: getIcon("mi:caret-up"),
    // dropIcon: getIcon("gridicons:dropdown"),
    // upIcon: getIcon("mi:caret-up"),
  }

}

// Result Section -- 911
// Sub Heading 
//  1- Result Banner  --- 9111
// 2 - Result Story Video --- 9112

let resultHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('9111') || d?.access?.includes('9112')) {
  resultHeading = {
    title: "Result",
    path: "#",
    icon: getIcon("dashicons:testimonial"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
    // dropIcon: getIcon("gridicons:dropdown"),
    // upIcon: getIcon("mi:caret-up"),
  }

}
let resultBannerSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('9111')) {
  resultBannerSubHeading = {
    title: "Result Banner",
    path: "/dashboard/resultBanner",
    navicon: getIcon("icon-park-solid:coupon"),
    cName: "sub-nav",
  }
}
//  ii - Add Scholarship    --- 52
let resultStorySubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('9112')) {
  resultStorySubHeading = {
    title: "Result Story",
    path: "/dashboard/resultStory",
    navicon: getIcon("eva:plus-square-fill"),
    cName: "sub-nav",
  }
}

// General Settting ( main Heading )   --- 10
//  sub Heading of General Setting ---
//    i -  Banner  ---- 101
//    add Banner  ---- 1011
//   ii - Logout users   --- 102
//  iii - subject  ---- 103
//  add Subject --- 1033
// iv Youtube ---- 104
//  add Youtube --- 1044

// v - TeleGran --- 105
//  add telegram ---- 1055
// vi - Category  --- 106
//  add Category - 1066
// vii - Sub category --- 107
//  add Sub Category ----- 1077

// viii - Contact Us  ----108
//  add Contact  -  1088
//  ix - Help & Support --- 109

// General Settting ( main Heading )   --- 10
let generalSettingHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('101')
  || d?.access?.includes('1011') || d?.access?.includes('102') ||
  d?.access?.includes('103') || d?.access?.includes('1033')
  || d?.access?.includes('104') || d?.access?.includes('1044')
  || d?.access?.includes('105') || d?.access?.includes('1055')
  || d?.access?.includes('106') || d?.access?.includes('1066')
  || d?.access?.includes('107') || d?.access?.includes('1077')
  || d?.access?.includes('108') || d?.access?.includes('1088')
  || d?.access?.includes('109')
  || d?.access?.includes('10001') || d?.access?.includes('10002')
  || d?.access?.includes('10003')
) {
  generalSettingHeading = {
    title: "General Settings",
    path: "#",
    icon: getIcon("ci:settings"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}
//   subHeading of Resourses
//   i - Banner   --- 101
//   add Banner ----  1011
let bannerSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('101') || d?.access?.includes('1011')) {
  bannerSubHeading = {
    title: "Banner",
    path: "/dashboard/banner",
    navicon: getIcon("game-icons:vertical-banner"),
    cName: "sub-nav",
  }
}

let ctaBannerSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('10002') || d?.access?.includes('10001')) {
  ctaBannerSubHeading = {
    title: "CTA Banner",
    path: "/dashboard/ctaBanner",
    navicon: getIcon("game-icons:vertical-banner"),
    cName: "sub-nav",
  }
}
//  ii - Logout Users ---- 102 

let lagoutUsersSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('102')) {
  lagoutUsersSubHeading = {
    title: "Logout Users",
    path: "/dashboard/logoutUsers",
    navicon: getIcon("mdi:user-minus"),
    cName: "sub-nav",
  }
}


// iii - Subject ---- 103 
//  add Subject ----- 1033
let subjectSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('103') || d?.access?.includes('1033')) {
  subjectSubHeading = {
    title: "Subject",
    path: "/dashboard/subject",
    navicon: getIcon("ic:baseline-subject"),
    cName: "sub-nav",
  }
}

// iv - YouTube   --- 104
//  add YouTube ---- 1044
let youtubeSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('104') || d?.access?.includes('1044')) {
  youtubeSubHeading = {
    title: "Youtube",
    path: "/dashboard/youtube",
    navicon: getIcon("fa:youtube"),
    cName: "sub-nav",
  }
}
// v - telegram    ---105
//  add telegram ---- 1055
let telegramSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('105') || d?.access?.includes('1055')) {
  telegramSubHeading = {
    title: "Telegram",
    path: "/dashboard/telegram",
    navicon: getIcon("fontisto:telegram"),
    cName: "sub-nav",
  }
}
// vi -  category  ---- 106
//  add category ---- 1066
let categorySubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('106') || d?.access?.includes('1066')) {
  categorySubHeading = {
    title: "Category",
    path: "/dashboard/category",
    navicon: getIcon("carbon:category"),
    cName: "sub-nav",
  }
}
// vii subCatgeory- ---- 107
//  add subCatgeory ----- 1077
let subCategorySubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('107') || d?.access?.includes('1077')) {
  subCategorySubHeading = {
    title: "Sub Category",
    path: "/dashboard/subCategory",
    navicon: getIcon("carbon:category-new-each"),
    cName: "sub-nav",
  }
}
// v - contact    ---108
//  add contact us ---- 1088
let contactUsSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('108') || d?.access?.includes('1088')) {
  contactUsSubHeading = {
    title: "Contact Us",
    path: "/dashboard/contactUs",
    navicon: getIcon("material-symbols:contact-mail-sharp"),
    cName: "sub-nav",
  }
}


//  help & support --- 109
let helpSupportSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('109')) {
  helpSupportSubHeading = {
    title: "Help and Support",
    path: "/dashboard/message",
    navicon: getIcon("ic:baseline-contact-support"),
    cName: "sub-nav",
  }
}


//  let FaqHeading = { };
//  if( d?.access?.includes('all') || d?.access?.includes('10003')  ){
//       FaqHeading =  {
//          title: "FAQs",
//          path: "#",
//          icon: getIcon("eva:calendar-fill"),
//          dropIcon: getIcon("gridicons:dropdown"),
//          upIcon: getIcon("mi:caret-up"),
//        }
//   }

let allFaqsSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('10003')) {
  allFaqsSubHeading = {
    title: "FAQs",
    path: "/dashboard/faqs",
    navicon: getIcon("eva:calendar-fill"),
    cName: "sub-nav",
  }
}
// subHeading of Scholar Ship
//  i - All Scholarship test    ---- 151

let logsHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('161')) {
  logsHeading = {
    title: "Logs",
    path: "#",
    icon: getIcon("eva:calendar-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}

let allLogsSubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('161')) {
  allLogsSubHeading = {
    title: "All Logs",
    path: "/dashboard/logs",
    navicon: getIcon("eva:calendar-fill"),
    cName: "sub-nav",
  }
}


let s3Heading = {};
if (d?.access?.includes('all') || d?.access?.includes('171')) {
  s3Heading = {
    title: "Cloud Storage",
    path: "#",
    icon: getIcon("clarity:storage-solid"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  }
}

let s3SubHeading = {};
if (d?.access?.includes('all') || d?.access?.includes('171')) {
  s3SubHeading = {
    title: "All Cloud Storage",
    path: "/dashboard/buckets",
    navicon: getIcon("clarity:storage-solid"),
    cName: "sub-nav",
  }
}




let navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon("eva:pie-chart-2-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  },
  {
    ...usersHeading,
    subNav: [
      {
        ...allUsersSubHeading,
      },
      {
        ...specificUsersSubHeading
      },
      {
        ...coursesPurchasesSubHeading,
      },
      {
        ...courseOrderTableSubHeading
      },
      {
        ...ebookPurchasesSubHeading,
      },
      {
        ...testSeriesPurchasesSubHeading,
      },
      {
        ...purchaseReportSubHeading,
      },
      {
        ...userReportSubHeading,
      },
      {
        ...callToSubHeading,
      },
      {
        ...webSiteSearchQuerySubHeading
      }
    ],
  },
  {
    ...storeHeading,
    subNav: [
      {
        ...storeCategory,
      },
      {


        ...storeBanner,
      },
      {


        ...storeFeaturedVideo,
      },
      {
        ...storeAlerts,
      },
      { ...storeCouponSubHeading },
      {
        ...storeProductsSubHeading,
      },
      {
        ...storeAddProductsSubHeading,
      },
      {
        ...storeOrderReportSubHeading,
      },
      {
        ...storePurchaseReportSubHeading
      },
      {
        ...storeWishlistSubHeading
      },
      { ...storeCartSubHeading },
      { ...storeUsersSubHeading },
      { ...storeTrashProductsSubHeading },
      { ...storeUserSearchSubHeading },
      { ...storeReviewSubHeading }
    ],
  },
  {
    ...batchesHeading,
    subNav: [{ ...allBatchesSubHeading }, { ...addBatchSubHeading }, { ...announcementsSubHeading }, { ...batchFeatureSubHeading }, { ...batchCommunitySubHeading }, { ...batchDoubtSubHeading }],
  },
  {
    ...ebookHeading,
    subNav: [{ ...allEbookSubHeading }, { ...addEbookSubHeading }],
  },
  {
    ...learningHeading,
    subNav: [{ ...learningChannelSubHeading }],
  },
  {
    ...testSeriesHeading,
    subNav: [{ ...allTestSeriesSubHeading }, { ...addTestSeriesSubHeading }],
  },
  {
    ...cmsHeading,
    subNav: [{ ...allBlogsSubHeading }, { ...addBlogSubHeading }],
  },
  {
    ...communityHeading,
    subNav: [{ ...allCommunitySubHeading }],
  },
  {
    ...pushNotificationHeading,
    subNav: [{ ...allNotificationSubHeading }, { ...addNotificationSubHeading }],
  },
  {
    ...quizHeading,
    subNav: [{ ...allQuizSubHeading }, { ...addQuizSubHeading }, { ...quizQuestionSectionSubHeading }],
  },
  {
    ...scholarshipTestHeading,
    subNav: [
      { ...allScholarshipTestSubHeading },
      { ...addScholarshipTestSubHeading },
    ],
  },
  {
    ...deepLinkHeading,
    subNav: [
      { ...allDeepLinkSubHeading },
      { ...addDeepLinkSubHeading },
    ],
  },
  {
    ...couponHeading,
    subNav: [{ ...allCouponSubHeading }, { ...addCouponSubHeading }],
  },
  {
    ...resourcesHeading,
    subNav: [
      { ...recordedVideoSubHeading },
      { ...pathyaKramSubHeading },
      { ...notesSubHeading },

      { ...pyqsSubHeading },
      { ...airSubHeading },
      { ...newsClipSubHeading },
      { ...teacherGCOBSubHeading },
      { ...allAlertSubHeading },
    ],
  },
  {
    ...assignmentHeading,
  },
  {
    ...staffHeading,
  },
  {
    ...testimonialHeading,
  },
  {
    ...resultHeading,
    subNav: [
      { ...resultBannerSubHeading },
      { ...resultStorySubHeading }
    ]
  },
  {
    ...generalSettingHeading,
    subNav: [
      { ...bannerSubHeading },
      { ...ctaBannerSubHeading },
      { ...lagoutUsersSubHeading },
      { ...subjectSubHeading },
      { ...youtubeSubHeading },
      { ...telegramSubHeading },
      { ...categorySubHeading },
      { ...subCategorySubHeading },
      { ...contactUsSubHeading },
      { ...helpSupportSubHeading },
      { ...allFaqsSubHeading }
    ]
  },
  {
    ...logsHeading,
    subNav: [
      { ...allLogsSubHeading },
    ]
  },
  {
    ...s3Heading,
    subNav: [
      { ...s3SubHeading },
    ]
  }
];

export default navConfig;
